<template>
  <div class="">
    <div class="profile-body pt-3">
      <custom-alert id="alert_box" v-if="displayAlert == true" :message="alertMessage" v-on:showedAlert="resetAlert" ></custom-alert>
      <div class="row">
        <div class="col-md-12 mb-2">
          <div class="card">
            <div class="card-header">
              <div class="header-title">
                <h3>Insurance</h3>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <img src="/images/insurance_section.png" >
                  </div>
                </div>
                </div>
              <div>
                <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <a href="https://landing.playershealth.com/afsoftball-coi-4/" target="_blank"><button type="button"  class="form-control">Request for COI</button></a>
                  </div>
                </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <button type="button" @click="uploadFile()" class="form-control">Upload COI</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <a href="https://app.playershealthclaims.com/landing/the-alliance-fastpitch-llc" target="_blank"><button type="button"  class="form-control">File a Claim</button></a>
                  </div>
                </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <button type="button" @click="downloadZip()" :disabled="totalCount==0"  class="form-control">Download COI</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                For any insurance-related inquiries, please get in touch with us at
                <a class="ml-1" href="mailto:insurance@thealliancefastpitch.com">insurance@thealliancefastpitch.com</a>
              </div>
              <div class="row">
                <div class="table-list col-md-12 mt-2 p-2 table-responsivenes">
                  <table class="table table-hover"  id="files_table">
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- profile body end-->
    <b-modal id="upload-file" hide-footer title="Insurance">
      <div class="d-block text-center">
        <VueUploadDropImages @handleFiles="handleFiles" @fileTypeError="fileTypeErrorSetup" :fileTypeError="fileTypeError" />
      </div>
      <b-button class="mt-3 col-md-2 right" variant="outline-danger" @click="handleImages()">Save</b-button>
    </b-modal>
  </div>
</template>


<script>
import VueUploadDropImages from '../file-upload';
import axios from "axios";
import CustomAlert from "../CustomAlert";
import $ from 'jquery';
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";

export default {
  name: "insurance",
  components : { VueUploadDropImages, CustomAlert },
  data(){
    return {
      alertMessage: "",
      displayAlert: false,
      insurance:[],
      loading:false,
      table:[],
      fileTypeError : '',
      member_id:null,
      totalCount : 0
    }
  },
  methods:{
    uploadFile() {
      this.$bvModal.show("upload-file");
    },
    handleFiles(files){
      this.files = files;
    },
    downloadZip(){
      this.showLoader = true;
      let member_id = JSON.parse(localStorage.getItem("team")).club_membership_id.member_id;
      axios({
        url: process.env.VUE_APP_URL + 'api/insurance/download/'+member_id,
        method: 'GET',
        responseType: 'arraybuffer',
        headers:this.adminHeaders
      }).then((response) => {
        this.showLoader = false;
        let blob = new Blob([response.data], {
          type: response.headers['content-type']
        })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = this.member_id+'.zip';
        link.click()
      }).catch(error => {
        console.log('test',error);
        this.showLoader = false;
      });
    },
    handleImages(){
      let formData = new FormData();
      let attachment = this.files;
      for (let i = 0; i < attachment.length; i++) {
        if(attachment[i].size <= 2 * 1024 * 1024) {
          if(attachment[i].type == 'application/pdf')
          formData.append('files[' + i + ']',attachment[i]);
          else{
            this.fileTypeError = `Only upload pdf files.`;
            return;
          }
        }else{
          this.fileTypeError='File size exceeds maximum limit 2 MB.';
          return;
        }
      }
      axios.post(process.env.VUE_APP_URL + "api/insurance/store/"+this.member_id, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (response.data.status === 'success') {
          this.$bvModal.hide("upload-file");
          this.alertMessage = response.data.message;
          this.displayAlert = true;
          this.table.ajax.reload();
        }
      })
          .catch(error => {
            console.log("error filter info")
          });
    },
    FilesList(){
      this.$set(this.adminHeaders, 'Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
      $.ajaxSetup({
        headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
          'ClientId':  this.ClientId
        }
      });
      this.loading = true;
      let self = this;
      this.table = $("#files_table").DataTable({
        dom: 'lfrtip',
        "bFilter": false,
        processing: true,
        serverSide: true,
        pageLength: 10,
        ajax: {
          url: process.env.VUE_APP_URL + 'api/insurance/list/'+this.member_id,
          type: 'POST',
          headers: this.adminHeaders,
        },
        responsive: true,
        drawCallback: function() {
          var api = this.api();
          self.totalCount = api.page.info().recordsTotal;
        },
        columns: [
          { data: 'file_name', name: 'file_name', orderable: true, render: (data)=>{return data || "N/A";}},
          { data: null, orderable: false,
            render: (data,type,row) => {
              let html =  `<a class="action-download" title="download"  data-id="${row.file_name}" href="javascript:void(0);"><i class="fas fa-cloud-download-alt mw-20"></i> </a>`;
              html += `<a class="action-delete" title="Delete" data-id="${row.file_name}" href="javascript:void(0);"><i class="fas fa-trash mw-20"></i></a>`;
              return html;
            }
          },
        ]
      });

      $(document).on('click',"table#files_table .action-delete",function() {
        var id = $(this).data("id");
        self.deleteFile(id);
      });
      $(document).on('click',"table#files_table .action-download",function() {
        var id = $(this).data("id");
        self.downloadFile(id);
      });
    },
    downloadFile(file){
      axios({
        url: process.env.VUE_APP_URL + 'api/insurance/downloadFile/'+this.member_id+'/'+file,
        method: 'GET',
        responseType: 'arraybuffer',
        headers:this.adminHeaders
      }).then((response) => {
        let blob = new Blob([response.data], {
          type: response.headers['content-type']
        })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file;
        link.click()
      }).catch(error => {
        console.log('test',error);
      });
    },
    deleteFile(file){
      axios({
        url: process.env.VUE_APP_URL + 'api/insurance/deleteFile/'+this.member_id+'/'+file,
        method: 'GET',
        headers:this.adminHeaders
      }).then((response) => {
        if (response.data.status === 'success') {
          this.alertMessage = response.data.message;
          this.displayAlert = true;
          this.table.ajax.reload();
        }
      }).catch(error => {
        console.log('test',error);
      });
    },
    fileTypeErrorSetup(fileTypeError){
      this.fileTypeError = fileTypeError;
    }

  },
  mounted() {
    this.member_id = JSON.parse(localStorage.getItem("team")).club_membership_id.member_id;
    this.FilesList();
  }
}

</script>
<style>
.modal-dialog {
  max-width: 50%;
}
</style>

<style scoped>

</style>