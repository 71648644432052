<template>
  <div class="">
    <custom-alert v-if="displayAlert==true" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
    <div class="page-banner  pb-3">
      <img src="/dist/img/coach_banner.png" style="width:100%;" alt="page-banner">
      <div class="page-title">
        <h5 class="text-white">Club Insurance(s)</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card mt-3 border-none">
          <div class="card-header bg-white">
            <div class="header-title">
              <h5>Insurance(s)</h5>
            </div>
        <div class="card-body">
          <b-table
              ref="insured_members"
              id="my-table"
              :items="clubInfo.insured_members"
              :fields="tblInsuredFields"
              sort-by="transaction_time"
              sort-desc="Descending"
              class="mt-3"
              hover
              show-empty
              :busy="isBusy"
          >
            <template #empty="scope">
              <h4>{{ scope.emptyText }}</h4>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(renew)="row">
              <div class="text-left pl-5 d-flex align-items-center">
                <div class="btn-group btn-group-sm">
                  <input class="form-check-input" type="checkbox" v-if="row.item.isRenew != undefined"
                         name="isRenew" v-model="row.item.isRenew" :key="updateKey" value="true" @change="changeRenewInsurance(row.index, row.item.id)">
                </div>
                <div class="mt-3">
                  <i v-if="row.item.isNotValid" class="fa fa-exclamation-circle" v-b-tooltip.hover title="To renew this certificate of insurance, please fill in the missing fields."></i>
                </div>
              </div>
            </template>
          </b-table>
        </div>
            </div>
          </div>
        </div>
      <div class="col-md-6 mb-4 mt-4">
        <b-card>
          <b-button v-b-modal.insurenceModal @click.prevent="insurenceInfoModal" :disabled="!insuranceFee">Purchase Certificate</b-button>
          <p class="text-danger" v-if="!insuranceFee">Certificate of Insurance purchase is disabled now.</p>
          <div class="insureddescription mt-3">
            <template v-for="(Insurance, y) in myInsurances">
              <div class="row forborder" v-if="totalQty>0" :key="y">
                <div class="col-md-6">
                  <span class="title" v-if="Insurance.insurance_type==2">{{ Insurance.company_name }}</span>
                  <span class="title" v-else>{{ Insurance.first_name + ' ' + Insurance.last_name }}</span>
                </div>
                <div class="col-md-6 text-right">
                  <a class="btn-edit btn float-right mt-0" @click="editInsurance(y)"><img
                      v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit</a>
                  <a class="btn-remove btn float-right mt-0" @click="removeInsurance(y)"><img
                      v-bind:src="'/dist/img/memberregistration/remove.png'" alt="edit_icon"> Remove</a>
                </div>
              </div>
            </template>
            <div v-if="totalQty === 0">
              <span class="ins-description">You currently have no Certificates of Insurance.</span>
            </div>
            <div v-if="insurenceError !== '' && totalQty==0">
              <span class="text-danger">{{ insurenceError }}</span>
            </div>
          </div>
        </b-card>
      </div>
      <div class="col-md-6 status_bar mt-4">
        <div class="col-md-12">
          <b-card>
            <div class="">
              <div class="float-left">
                Total Certificates of Insurance: <h4 class="m-0">{{ totalQty }}</h4>
              </div>
              <div class="float-right">
                <b-button variant="primary" disabled>
                  {{ totalAmt }}
                  <b-badge variant="light">$</b-badge>
                </b-button>
              </div>
            </div>
          </b-card>
        </div>
        <div class="col-md-12 mt-3">
          <label class="form-label">Pay with Credit Card</label>
          <b-card>
            <div class="row">
              <div class="col-md-12 p-0">
                <div class="form-group">
                  <label>Card Number<sup class="text-danger">*</sup></label>
                  <input type="text" maxlength="16" placeholder="1234 5678 9012 3456" v-model="payment.cardNumber"
                         class="form-control">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8 pl-0">
                <label>Expiration Date<sup class="text-danger">*</sup></label>
                <div class="row">
                  <div class="col-md-6 pl-0">
                    <select id="expiration-month" required="required" v-model="payment.expiration_month"
                            class="form-control form-select">
                      <option value="">Month</option>
                      <option>01</option>
                      <option>02</option>
                      <option>03</option>
                      <option>04</option>
                      <option>05</option>
                      <option>06</option>
                      <option>07</option>
                      <option>08</option>
                      <option>09</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                    </select>
                  </div>
                  <div class="col-md-6 p-0">
                    <select id="expiration-year" required="required" v-model="payment.expiration_year"
                            class="form-select form-control">
                      <option value="">Year</option>
                      <option v-for="year in yearArr" :value="year" :key="year">{{ year }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-4 p-0">
                <label>CVV/CVC<sup class="text-danger">*</sup></label>
                <input maxlength="4" type="password" v-model="payment.cvv" class="form-control">
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12 p-0">
                  <div class="form-group mb-0">
                      <label class="form-label">Name on Card<sup class="text-danger">*</sup></label>
                      <input type="text" placeholder="John Doe" class="form-control" id="billing_firstname" name="payment.billing_firstname" v-model="payment.billing_firstname">
                      <span class="text-sm text-danger">{{ errors.billing_firstname}}</span>
                  </div>
              </div>
            </div>
            <div class="row" v-show="paymentError">
              <div class="col-md-12">
                <p class="text-danger mt-2 mb-0">{{ paymentError }}</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-3 pl-0">
                <router-link class="btn btn-secondary btn-block m-0" to="/club/club-insurance-information">Back
                </router-link>
              </div>
              <div class="col-md-9 p-0">
                <button class="btn btn-primary btn-block" @click="PurchaseClubInsurance">{{ payNow }}</button>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </div>

    <b-modal id="insurenceModal"
             ref="modal"
             ok-only
             ok-title="submit"
             @hidden="resetModal"
             @ok="handleOk" size="lg" centered title="Certificate of Insurance Request">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="popupbody">
          <!--<h5>Certificate of Insurance Request</h5>-->
          <p class="font-13 mb-1">Complete for each third party requiring a certificate of insurance from your club with
            respect to USA Water Ski & Wake Sports sanctioned events. Each request is $50.</p>
          <p class="font-13 text-danger"><i>Note: <span v-html="insuranceNote"></span></i></p>
          <div class="newname-inner">
            <div class="row">
              <div class="col-md-6" v-if="!notACompany">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="companyName" class="form-label">Company Name <sup class="text-danger">*</sup></label>
                      <input id="companyName" type="text" maxlength="250" class="form-control" name="form.name" value=""
                             v-model="form.company_name">
                      <span class="text-sm text-danger">{{ errors.company_name }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3" v-if="notACompany">
                <div class="form-group">
                  <label for="firstName" class="form-label">First Name <sup class="text-danger">*</sup></label>
                  <input id="firstName" type="text" class="form-control" maxlength="90" name="form.first_name" value=""
                         v-model="form.first_name">
                  <span class="text-sm text-danger">{{ errors.first_name }}</span>
                </div>

              </div>
              <div class="col-md-3" v-if="notACompany">
                <div class="form-group">
                  <label for="lastName" class="form-label">Last Name<sup class="text-danger">*</sup></label>
                  <input id="lastName" type="text" class="form-control" maxlength="90" name="form.last_name" value=""
                         v-model="form.last_name">
                  <span class="text-sm text-danger">{{ errors.last_name }}</span>
                </div>
              </div>
              <div class="col-md-3 mt-4">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="notACompany"
                         name="notACompany" v-model="notACompany" value="1">
                  <label class="d-flex mr-1" for="notACompany">Not a company</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="address1" class="form-label">Address 1<sup class="text-danger">*</sup> </label>
                  <input id="address1" type="text" class="form-control" maxlength="45" name="form.address1" value=""
                         v-model="form.address1">
                  <span class="text-sm text-danger">{{ errors.address1 }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="address2" class="form-label">Address 2 </label>
                  <input id="address2" type="text" class="form-control" maxlength="45" name="form.address2" value=""
                         v-model="form.address2">
                  <span class="text-sm text-danger">{{ errors.address2 }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="city" class="form-label">City<sup class="text-danger">*</sup></label>
                  <input id="city" type="text" class="form-control" maxlength="45" name="form.city" value=""
                         v-model="form.city">
                  <span class="text-sm text-danger">{{ errors.city }}</span>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="state" class="form-label">State <sup class="text-danger">*</sup> </label>
                  <select class="form-control" id="state" name="form.state_code" v-model="form.state_code">
                    <option v-for="listOfState in listOfStates" :key="listOfState.state"
                            :value="listOfState.state_code">{{ listOfState.state }}
                    </option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.state_code }}</span>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="zipCode" class="form-label">Zip Code <sup class="text-danger">*</sup> </label>
                  <input id="zipCode" type="text" class="form-control" maxlength="15" name="form.zip"
                         v-model="form.zip">
                  <span class="text-sm text-danger">{{ errors.zip }}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="home_phone" class="form-label">Home Phone <sup class="text-danger">*</sup> </label>
                  <vue-tel-input id="home_phone" v-model="form.home_phone"  @validate="validateTelinput"  ref="phone" maxlength="20"
                                 v-bind="homeProps" :validCharactersOnly="true"></vue-tel-input>
                  <span class="text-sm text-danger">{{ errors.home_phone }}</span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="yearRoundclubactivity" class="form-label">The Certificate for<sup class="text-danger">*</sup>
                  </label>
                  <div class="form-group ">
                    <label class="form-label" for="yearRoundclubactivity"> <input type="radio"
                                                                                  id="yearRoundclubactivity"
                                                                                  name="form.certificate_type"
                                                                                  v-model="form.certificate_type"
                                                                                  value="year"> Year - Round Club
                      Activity</label>
                    <label class="form-label" for="specificEvent">

                      <input type="radio" id="specificEvent" maxlength="20" name="form.certificate_type"
                             v-model="form.certificate_type" value="specificEvent"> Specific Event</label><br>
                    <span class="text-sm text-danger">{{ errors.certificate_type }}</span>
                  </div>
                </div>

              </div>
              <div class="col-md-12">
                <div class="form-group certificatefor" v-if="form.certificate_type == 'specificEvent'">
                  <span class="font-13 text-danger"><i>Note: Certificate for Specific sanctioned events will not be issued until the event is sanctioned and approved by USA Water Ski & Wake Sports.</i></span><br>
                  <label for="specific_event_title" class="form-label">Specific Event Title<sup class="text-danger">*</sup>
                  </label>
                  <input type="text" class="form-control" maxlength="45" name="form.specific_event_title" id="specific_event_title"
                         v-model="form.specific_event_title">
                  <span class="text-sm text-danger">{{ errors.specific_event_title }}</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group specificeventtype" v-if="form.certificate_type == 'specificEvent'">
                  <label for="tournament" class="form-label">Specific Sanctioned Event Type <sup class="text-danger">*</sup></label>
                  <div class="form-group row">
                    <label class="form-label" for="tournament"><input type="radio" id="tournament"
                                                                      name="form.sanctioned_type"
                                                                      v-model="form.sanctioned_type" value="tournament">
                      Tournament</label>
                    <label class="form-label" for="practice"><input type="radio" id="practice"
                                                                    name="form.sanctioned_type"
                                                                    v-model="form.sanctioned_type" value="practice">
                      Practice</label><br>
                    <label class="form-label" for="basicskillclinic"><input type="radio" id="basicskillclinic"
                                                                            name="form.sanctioned_type"
                                                                            v-model="form.sanctioned_type"
                                                                            value="basicskillclinic"> Basic Skills
                      Clinic</label>
                    <label class="form-label" for="exhibition"><input type="radio" id="exhibition"
                                                                      name="form.sanctioned_type"
                                                                      v-model="form.sanctioned_type" value="exhibition">
                      Exhibition</label><br>
                    <span class="text-sm text-danger">{{ errors.sanctioned_type }}</span>
                  </div>
                  <div class="row" v-if="form.certificate_type == 'specificEvent'">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="eventSanction" class="form-label">Event Sanction# <sup class="text-danger">*</sup></label>
                        <input id="eventSanction" type="text" name="form.event_sanction" maxlength="45"
                               v-model="form.event_sanction" class="form-control">
                        <span class="text-sm text-danger">{{ errors.event_sanction }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group insurence-datepicker">
                          <label for="specific_event_start_date" class="form-label">Specific Events Start Date <sup class="text-danger">*</sup></label><br>

                          <date-pick
                              class="form-control"
                              id="specific_event_start_date"
                              v-model="form.specific_event_start_date"
                              :pickTime="false"
                              name="form.specific_event_start_date"
                              @input="specialEventDateValidation(form.specific_event_start_date)"
                              :selectableYearRange="{from: minYear, to: teamExpYear}"
                              :format="'MM/DD/YYYY'"
                              v-mask="'##/##/####'"
                              :isDateDisabled="isFutureDate"
                              placeholder="MM/DD/YYYY"
                              :parseDate="parseDatePick"
                          ></date-pick>

                          <!--<input id="specificeventdate" type="text" name="form.specific_event_start_date" maxlength="45" v-model="form.specific_event_start_date" class="form-control">-->
                          <span class="text-sm text-danger">{{ errors.specific_event_start_date }}</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group insurence-datepicker">
                          <label for="specific_event_end_date" class="form-label">Specific Events End Date<sup class="text-danger">*</sup></label><br>

                          <date-pick
                              class="form-control"
                              id="specific_event_end_date"
                              v-model="form.specific_event_end_date"
                              :pickTime="false"
                              name="form.specific_event_end_date"
                              @input="specialEventEndDateValidation(form.specific_event_end_date)"
                              :selectableYearRange="{from: minYear, to: teamExpYear}"
                              :format="'MM/DD/YYYY'"
                              v-mask="'##/##/####'"
                              :isDateDisabled="isFutureDate"
                              placeholder="MM/DD/YYYY"
                              :parseDate="parseDatePick"
                          ></date-pick>

                          <!--<input id="specificeventdate" type="text" name="form.specific_event_end_date" maxlength="45" v-model="form.specific_event_end_date" class="form-control">-->
                          <span class="text-sm text-danger">{{ errors.specific_event_end_date }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group ">
                  <label class="form-label">Has the club entered into any agreement, contract, or permit which contains
                    assumption of liability, indemnification, or hold harmless language? <sup class="text-danger">*</sup></label>
                  <div class="">
                    <label class="form-label" for="agreeyes"><input type="radio" id="agreeyes"
                                                                    name="form.team_has_any_agreement"
                                                                    v-model="form.team_has_any_agreement" value="Y"> Yes</label><br>

                  </div>
                  <div class="">
                    <label class="form-label" for="agreeno"> <input type="radio" id="agreeno"
                                                                    name="form.team_has_any_agreement"
                                                                    v-model="form.team_has_any_agreement" value="N"> No</label><br>
                  </div>
                  <span class="text-sm text-danger">{{ errors.team_has_any_agreement }}</span>
                  <span
                  class="font-13 text-danger"><i> (A copy of the document should be forwarded to USA-WSWS)</i></span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group ">
                  <label class="form-label">Does the certificate holder require additional insured status? <sup class="text-danger">*</sup></label>
                  <div class="">
                    <label class="form-label" for="ins-status-yes"><input type="radio" id="ins-status-yes"
                                                                          name="form.additional_insurance"
                                                                          v-model="form.additional_insurance" value="Y">
                      Yes</label><br>

                  </div>
                  <div class="">
                    <label class="form-label" for="ins-status-no"> <input type="radio" id="ins-status-no"
                                                                          name="form.additional_insurance"
                                                                          v-model="form.additional_insurance" value="N">
                      No</label><br></div>
                  <span class="text-sm text-danger">{{ errors.additional_insurance }}</span>
                  <div class="form-group " v-show="form.additional_insurance=='Y'">
                    <b-form-group label="Please check the relationship of the additional insured:">
                      <b-form-checkbox-group
                          id="additional_insurance_member"
                          v-model="form.additional_insurance_member"
                          :options="additionalInsuranceMemberOptions"
                          name="additional_insurance_member"
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>

  </div>
</template>

<script>
import CustomAlert from "./../../CustomAlert";
import DatePick from 'vue-date-pick';
import {TheMask} from 'vue-the-mask'
import {VueTelInput} from 'vue-tel-input';
import axios from 'axios';
import moment from "moment";
import insurance from "../insurance";

const validateName = name => {
  if ([undefined, null, ''].includes(name)) {
    return {valid: false, error: "This field is required"};
  }
  return {valid: true, error: null};
};
const validateFirstName = (name, fieldName = '',optional=false) => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if ([undefined, null, ''].includes(names)) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!optional && !names.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateLastName = (name, fieldName = '',optional=false) => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if ([undefined, null, ''].includes(names)) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!optional && !names.match(/^[a-zA-Z-\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters and hyphens only. No other characters " };
  }
  return { valid: true, error: null };
};
const validatePhone = (phone, validTelinput, phoneField= '' ) => {
    let phoneText = ( phoneField != '' ? phoneField : "This field");
    if ([undefined, null, ''].includes(phone)) {
        return { valid: false, error: phoneText+' is required .' };
    }
    if(validTelinput == false && phone.length){
    return { valid: false, error: 'Enter the Exact phone number(Example:+XX XX XXXX XXXX).' };
    }
    return { valid: true, error: null };
}

const validateSelect = select => {
  if (select == undefined) {
    return {valid: false, error: "This field is required"};
  }
  return {valid: true, error: null};
};
const validateRadio = radio => {
  if (radio == undefined) {
    return {valid: false, error: "This field is required"};
  }
  return {valid: true, error: null};
};
const validateCheck = choices => {
  console.log('v='+choices);
  if(choices == undefined) return { valid: true, error: null };
  return { valid: false, error: "This field is required" };
}
export default {
  name: "PurchaseClubInsurance",
  components: {CustomAlert, DatePick, VueTelInput},
  data() {
    return {
      teamExpYear:'',
      minYear : new Date().getFullYear(),
      teamExpDate:"",
      payment: {
        cardNumber: "",
        expiration_month: "",
        expiration_year: "",
        cvv: "",
        billing_firstname: "",
        name_on_card: ""
      },
      yearArr: [],
      totalAmt: 0,
      totalQty: 0,
      updateKey: 0,
      paymentError: "",
      insurenceError: "",
      validTelinput: false,
      form: {
        first_name: '',
        last_name: '',
        company_name: '',
        insurance_type: '',
        home_phone: '',
        home_phone_country_code: '',
        home_phone_extension: '',
        work_phone: '',
        work_phone_country_code: '',
        work_phone_extension: '',
        fax: '',
        address1: '',
        address2: '',
        city: '',
        state_code: '',
        state: '',
        zip: '',
        country: '',
        certificate_type: '',
        sanctioned_type: '',
        event_sanction: '',
        specific_event_title: '',
        specific_event_start_date: '',
        specific_event_end_date: '',
        team_has_any_agreement: '',
        additional_insurance: '',
        additional_insurance_member: "sponsor",
        index: '',
        registration_id: ''
      },
      valid: true,
      success: false,
      errors: {},
      message: null,
      edit: false,
      myInsurances: [],
      options: {placeholder: "Phone Number"},
      homeProps: {
        mode: "international",
        defaultCountry: "US",
        placeholder: "Home Number",
        maxLen: 20,
      },
      notACompany: false,
      clubInfo:{},
      tblInsuredFields: [
        { key: 'company_name', sortable: true, label: 'Company Name' },
        { key: 'first_name', sortable: true },
        { key: 'last_name', sortable: true },
        { key: 'transaction_code', sortable: true, label: 'Transaction Id' },
        { key: 'start_date', sortable: true, label: 'Start Date',formatter: 'usdate' },
        { key: 'end_date', sortable: true, label: 'End Date',formatter: 'usdate' },
        { key: 'transaction_time', sortable: true, label: 'Purchased Date', formatter: 'usdate'},
        {key: 'renew', sortable: false},
      ],
      isBusy: false,
      payNow: 'Pay Now',
      insuranceFee: 0,
      sanctionFee: 0,
    }
  },
  methods: {
    specialEventDateValidation(value){
      if(new Date(moment(this.teamExpDate.valid_thru).format('MM/DD/YYYY')).getTime() < new Date(value).getTime()){
        this.valid = false;
        this.errors.specific_event_start_date = "Your selected date is exceed of your Expire date";
      }else if(new Date().getTime() > new Date(value).getTime()){
        this.valid = false;
        this.errors.specific_event_start_date = "Your selected date is Invalid";
      }else{
        this.errors.specific_event_start_date = "";
      }
    },
    specialEventEndDateValidation(value){
      if(new Date(moment(this.teamExpDate.valid_thru).format('MM/DD/YYYY')).getTime() < new Date(value).getTime()){
        this.valid = false;
        this.errors.specific_event_end_date = "Your selected date is exceed of your Expire date";
      }else if(new Date().getTime() > new Date(value).getTime()){
        this.valid = false;
        this.errors.specific_event_end_date = "Your selected date is Invalid";
      }else if(new Date(this.form.specific_event_start_date).getTime() > new Date(value).getTime()){
        this.valid = false;
        this.errors.specific_event_end_date = "Your selected end date is earlier than start date";
      }else{
        this.errors.specific_event_end_date = "";
      }
    },
    toggleBusy() {
      this.isBusy = !this.isBusy
    },
    formatDatenew(value) {
        var date = moment(value.trim());
        if (date.isValid()) {
          return date.format('MM/DD/YYYY HH:mm:ss');
        } else {
          return 'N/A';
        }
      },
      validateTelinput(obj){
          this.validTelinput = obj.valid;
          if(obj.valid){
              this.errors.home_phone = '';
          }
      },
    PurchaseClubInsurance() {
      this.insurenceError = "";
      this.paymentError = "";
      if(this.payment.billing_firstname == ""){
        this.paymentError = "Card Holders Name is required";
        return;
      }
      this.payment.name_on_card = this.payment.billing_firstname;
      let payInsurances = {
        payment: this.payment,
        insurances: this.myInsurances,
      };
      this.payNow = "Payment Processing...";
      axios.post(this.basePath + "api/club/add_insurance", payInsurances, {headers: this.clubHeaders})
          .then((response) => {
            const resp = response.data;
            this.payNow = "Pay Now";
            if (resp.status == 'success') {
              this.loadClubInfo();
              this.alertMessage = "Insurance has been added into your club";
              this.displayAlert = true;
              this.myInsurances = [];
              this.payment = {
                cardNumber: "",
                expiration_month: "",
                expiration_year: "",
                cvv: ""
              };
              this.totalAmt = 0;
              this.totalQty = 0;

            } else if (resp.status == 'error') {
              if (resp.errors.type == 1) {
                this.insurenceError = resp.message;
              } else {
                this.paymentError = (resp.errors.type == 2 ? resp.message : resp.errors);
              }
            }
          }).catch((error) => {
        console.log(error);
        this.payNow = "Pay Now";
      });
    },
    insurenceInfoModal() {
      this.edit = false;
      this.form = {};
      this.errors = {};
    },
    resetModal() {
      this.form = {};
      this.errors = {};
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.validate()) {
        return;
      }
      if (this.form.first_name != '' && this.form.last_name != '' && this.notACompany == true) {
        this.form.insurance_type = "1";
        this.form.company_name = '';
      } else if (this.form.company_name != '') {
        this.form.insurance_type = "2";
      }
      this.form.registration_id = this.clubInfo.registration.id;
      if (this.edit == false) {
        this.form.index = this.myInsurances.length;
      }
      if (this.form.id != undefined && this.myInsurances.filter(insurance => insurance.id == this.form.id).length == 0) {
          this.clubInfo.insured_members[this.form.index].isNotValid = false;
          this.clubInfo.insured_members[this.form.index].isRenew = true;
          this.$refs.insured_members.refresh();
          this.form.index = this.myInsurances.length;
          this.myInsurances[this.form.index] = this.form;
          this.myInsurances[this.form.index].fulfilled = 0;
          this.myInsurances[this.form.index].fulfilled_date = null;
      } else {
          this.myInsurances[this.form.index] = this.form;
      }
      console.log('---------------');
      console.log(this.myInsurances);
      this.totalQty = this.myInsurances.length;
      this.totalAmt = (this.totalQty * this.insuranceFee);
      this.valid = true;
      this.$nextTick(() => {
        this.$bvModal.hide('insurenceModal')
      });
    },
    validate() {
      this.errors = {}
      this.valid = false;

      if (![undefined, null, ''].includes(this.form.home_phone) && this.form.home_phone.length == 15) {
        this.validTelinput = true;
      } else {
        this.validTelinput = false;
      }

      const validFirstName = validateFirstName(this.form.first_name);
      const validLastName = validateLastName(this.form.last_name);
      const validCompanyName = validateName(this.form.company_name);
      const validAddress1 = validateName(this.form.address1);
      const validStateCode = validateName(this.form.state_code);
      const validCity = validateName(this.form.city);
      const validZip = validateName(this.form.zip);
      //const validHomePhoneCode = validateName(this.form.home_phone_country_code);
      // const validHomePhone = validatePhone(this.form.home_phone);
      const validHomePhone = validatePhone(this.form.home_phone, this.validTelinput);
        this.errors.home_phone = validHomePhone.error;
      const validCertificateType = validateName(this.form.certificate_type);
      const validSpecificEventTitle = validateName(this.form.specific_event_title);
      const validSanctionedType = validateRadio(this.form.sanctioned_type);
      const validEventSanctionNo = validateName(this.form.event_sanction);
      const validEventSanctionDate = validateName(this.form.specific_event_start_date);
      const validEventSanctionEndDate = validateName(this.form.specific_event_end_date);
      const validTeamHasAnyAgreement = validateRadio(this.form.team_has_any_agreement);
      const validAdditionalInsurance = validateRadio(this.form.additional_insurance);
      const validNotACompany = validateCheck(this.notACompany);

      this.errors.first_name = validFirstName.error; //'Please Enter First Name';
      this.errors.last_name = validLastName.error; //'Please Enter Last Name';
      this.errors.company_name = validCompanyName.error;//'Please Company Name';
      this.errors.address1 = validAddress1.error;
      this.errors.state_code = validStateCode.error;
      this.errors.city = validCity.error;
      this.errors.zip = validZip.error;
      //this.errors.home_phone_country_code = validHomePhoneCode.error;
      this.errors.home_phone = validHomePhone.error;
      this.errors.certificate_type = validCertificateType.error;
      this.errors.specific_event_title = validSpecificEventTitle.error;
      this.errors.specific_event_start_date = validEventSanctionDate.error;
      this.errors.specific_event_end_date = validEventSanctionEndDate.error;
      this.errors.sanctioned_type = validSanctionedType.error;
      this.errors.event_sanction = validEventSanctionNo.error;
      this.errors.team_has_any_agreement = validTeamHasAnyAgreement.error;
      this.errors.additional_insurance = validAdditionalInsurance.error;

      /*if (validFirstName.valid || validLastName.valid) {
        this.errors.company_name = '';
      }
      if (validCompanyName.valid) {
        this.errors.first_name = '';
        this.errors.last_name = '';
      }*/
      if(this.form.certificate_type == 'year') {
        this.errors.sanctioned_type = '';
      }
      if(this.form.certificate_type == 'specificEvent' && this.form.specific_event_start_date != ''){
        if(new Date(moment(this.teamExpDate.valid_thru).format('MM/DD/YYYY')).getTime() < new Date(this.form.specific_event_start_date).getTime()){
              this.valid = false;
              this.errors.specific_event_start_date = "Your selected date is exceed of your expire date";
            }
            if(new Date().getTime() > new Date(this.form.specific_event_start_date).getTime()){
              this.valid = false;
              this.errors.specific_event_start_date= "Your selected date is Invalid";
          }
      }
      if(this.form.certificate_type == 'specificEvent' && this.form.specific_event_end_date != ''){
        if(new Date(moment(this.teamExpDate.valid_thru).format('MM/DD/YYYY')).getTime() < new Date(this.form.specific_event_end_date).getTime()){
              this.valid = false;
              this.errors.specific_event_end_date = "Your selected date is exceed of your expire date";
            }
            if(new Date().getTime() > new Date(this.form.specific_event_end_date).getTime()){
              this.valid = false;
              this.errors.specific_event_end_date= "Your selected date is Invalid";
          }
      }
      if ((validCompanyName.valid || (validFirstName.valid && validLastName.valid)) && validAddress1.valid
          && validStateCode.valid && validCity.valid && validZip.valid && validHomePhone.valid
          && validCertificateType.valid
          && validTeamHasAnyAgreement.valid && validAdditionalInsurance.valid ) {
        this.valid = true;
        if(this.notACompany && (!validFirstName.valid || !validLastName.valid)) {
          this.valid = false;
        } else if(!this.notACompany && !validCompanyName.valid) {
          this.valid = false;
        }
        if(this.form.certificate_type == 'specificEvent' && (!validEventSanctionDate.valid || !validEventSanctionEndDate.valid || !validSpecificEventTitle.valid || !validSanctionedType.valid || !validEventSanctionNo.valid)) {
          this.valid = false;
        } else {
          if(validEventSanctionDate.valid && !this.validateDate(this.form.specific_event_start_date)) {
            this.valid = false;
            this.errors.specific_event_start_date = "Invalid Date";
          }
          if(this.form.certificate_type == 'specificEvent' && this.form.specific_event_start_date != '' && new Date(moment(this.teamExpDate.valid_thru).format('MM/DD/YYYY')).getTime() < new Date(this.form.specific_event_start_date).getTime()){
            this.valid = false;
            this.errors.specific_event_start_date = "Your selected date is exceed of your expire date";
          }
          if(this.form.certificate_type == 'specificEvent' && this.form.specific_event_start_date != '' && new Date().getTime() > new Date(this.form.specific_event_start_date).getTime()){
              this.valid = false;
              this.errors.specific_event_start_date= "Your selected date is Invalid";
          }

          if(validEventSanctionEndDate.valid && !this.validateDate(this.form.specific_event_end_date)) {
            this.valid = false;
            this.errors.specific_event_end_date = "Invalid Date";
          }
          if(this.form.certificate_type == 'specificEvent' && this.form.specific_event_end_date != '' && new Date(moment(this.teamExpDate.valid_thru).format('MM/DD/YYYY')).getTime() < new Date(this.form.specific_event_end_date).getTime()){
            this.valid = false;
            this.errors.specific_event_end_date = "Your selected date is exceed of your expire date";
          }
          if(this.form.certificate_type == 'specificEvent' && this.form.specific_event_end_date != '' && new Date().getTime() > new Date(this.form.specific_event_end_date).getTime()){
              this.valid = false;
              this.errors.specific_event_end_date= "Your selected date is Invalid";
          }
           if(validEventSanctionEndDate.valid && this.form.specific_event_end_date != '' && new Date(this.form.specific_event_start_date).getTime() > new Date(this.form.specific_event_end_date).getTime()) {
            this.valid = false;
            this.errors.specific_event_end_date = "Your selected end date is earalier than start date";
          }
        }
        if(this.valid) {
          this.errors = {}
        }
      }
      return this.valid;
    },
    duplicateValidate(){
      let startDate = moment().startOf('year');
      let duplicateRecord = this.clubInfo.insured_members.filter(
          insurance => (moment(insurance.start_date).isSameOrAfter(startDate) &&
              (this.form.insurance_type == "2" && insurance.company_name == this.form.company_name
                  && this.form.company_name != null) ||
              (this.form.insurance_type == "1" && insurance.first_name == this.form.first_name &&
                  this.form.first_name != null && insurance.last_name == this.form.last_name)));
      if (duplicateRecord.length > 0) {
        return true;
      }
      return false;
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date <= currentDate;
    },
    removeInsurance(i) {
      let removeInsurance = this.myInsurances[i];
      if (removeInsurance.id) {
        let index = this.clubInfo.insured_members.findIndex(insurance => insurance.id == removeInsurance.id);
        this.clubInfo.insured_members[index].isRenew = false;
        this.editInsuranceRow(index, true);
        if (this.duplicateValidate()) {
          this.clubInfo.insured_members[index].isNotValid = false;
        } else if (!this.validate()) {
          this.clubInfo.insured_members[index].isNotValid = true;
        }
        this.$refs.insured_members.refresh();
      }
      this.myInsurances.splice(i, 1);
      if (this.myInsurances.length == 0) {
        this.valid = false;
      }
      this.totalQty = this.myInsurances.length;
      this.totalAmt = (this.totalQty * this.insuranceFee);
    },
    editInsurance(i) {
      this.edit = true;
      this.$bvModal.show('insurenceModal');
      this.form = {
        first_name: this.myInsurances[i].first_name,
        last_name: this.myInsurances[i].last_name,
        company_name: this.myInsurances[i].company_name,
        insurance_type: this.myInsurances[i].insurance_type,
        home_phone: this.myInsurances[i].home_phone,
        work_phone: this.myInsurances[i].work_phone,
        fax: this.myInsurances[i].fax,
        address1: this.myInsurances[i].address1,
        address2: this.myInsurances[i].address2,
        city: this.myInsurances[i].city,
        state_code: this.myInsurances[i].state_code,
        state: this.myInsurances[i].state,
        zip: this.myInsurances[i].zip,
        country: this.myInsurances[i].country,
        certificate_type: this.myInsurances[i].certificate_type,
        sanctioned_type: this.myInsurances[i].sanctioned_type,
        event_sanction: this.myInsurances[i].event_sanction,
        specific_event_title: this.myInsurances[i].specific_event_title,
        specific_event_start_date: this.myInsurances[i].specific_event_start_date,
        specific_event_end_date: this.myInsurances[i].specific_event_end_date,
        team_has_any_agreement: this.myInsurances[i].team_has_any_agreement,
        additional_insurance: this.myInsurances[i].additional_insurance,
        additional_insurance_member: this.myInsurances[i].additional_insurance_member,
        index: i,
        registration_id : this.clubInfo.registration.id,
      };
      this.notACompany = this.form.insurance_type == "1";
      if(this.myInsurances[this.form.index].id != undefined){
        this.form.id = this.myInsurances[this.form.index].id;
      }
    },
    editInsuranceRow(i,isRenewValid = false) {
      this.edit = true;
      if (!isRenewValid) {
        this.$bvModal.show('insurenceModal');
      }
      this.form = {
        first_name: this.clubInfo.insured_members[i].first_name,
        last_name: this.clubInfo.insured_members[i].last_name,
        company_name: this.clubInfo.insured_members[i].company_name,
        insurance_type: this.clubInfo.insured_members[i].insurance_type,
        home_phone: this.clubInfo.insured_members[i].home_phone,
        work_phone: this.clubInfo.insured_members[i].work_phone,
        fax: this.clubInfo.insured_members[i].fax,
        address1: this.clubInfo.insured_members[i].address1,
        address2: this.clubInfo.insured_members[i].address2,
        city: this.clubInfo.insured_members[i].city,
        state_code: this.clubInfo.insured_members[i].state_code,
        state: this.clubInfo.insured_members[i].state,
        zip: this.clubInfo.insured_members[i].zip,
        country: this.clubInfo.insured_members[i].country,
        certificate_type: this.clubInfo.insured_members[i].certificate_type,
        sanctioned_type: this.clubInfo.insured_members[i].sanctioned_type,
        event_sanction: this.clubInfo.insured_members[i].event_sanction,
        specific_event_title: this.clubInfo.insured_members[i].specific_event_title,
        specific_event_start_date: this.clubInfo.insured_members[i].specific_event_start_date,
        specific_event_end_date: this.clubInfo.insured_members[i].specific_event_end_date,
        team_has_any_agreement: this.clubInfo.insured_members[i].team_has_any_agreement,
        additional_insurance: this.clubInfo.insured_members[i].additional_insurance,
        additional_insurance_member: this.clubInfo.insured_members[i].additional_insurance_member,
        index: i,
        registration_id : this.clubInfo.registration.id,
        id: this.clubInfo.insured_members[i].id
      };
      this.notACompany = this.form.insurance_type == "1";
    },
    async changeRenewInsurance(i, id) {
      if (this.clubInfo.insured_members[i].isRenew) {
        this.editInsuranceRow(i, true);
        if (this.duplicateValidate()) {
          let message = "Are you certain you want to proceed with the current year's insurance purchase once again? Before proceeding, please make sure the details are correct.";
          try {
            const shouldProceed = await this.$bvModal.msgBoxConfirm(message, {
              title: 'Confirm Insurance Renewal',
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'Renew',
              cancelTitle: 'Cancel',
              footerClass: 'p-2',
              hideHeaderClose: false,
              noCloseOnBackdrop: true,
              noCloseOnEsc: true,
              centered: false,
              innerHtml: true,
              bodyClass: 'modal-body-90'
            });

            if (!shouldProceed) {
              this.clubInfo.insured_members[i].isRenew = false;
              this.updateKey++;
              return;
            }
          } catch (error) {
            console.error('Error:', error);
            return;
          }
        }
      if (!this.validate()) {
          this.clubInfo.insured_members[i].isRenew = false;
          this.$bvModal.show('insurenceModal');
        } else {
          this.clubInfo.insured_members[i].isRenew = true;
          this.clubInfo.insured_members[i].fulfilled = 0;
          this.clubInfo.insured_members[i].fulfilled_date = null;
          this.myInsurances.push(this.clubInfo.insured_members[i]);
        }
      } else {
        this.myInsurances = this.myInsurances.filter(insurance => insurance.id != id);
        this.editInsuranceRow(i, true);
        if (this.duplicateValidate()){
          this.clubInfo.insured_members[i].isNotValid = false;
        } else if (!this.validate()) {
          this.clubInfo.insured_members[i].isNotValid = true;
        }
      }
      this.totalQty = this.myInsurances.length;
      this.totalAmt = (this.totalQty * this.insuranceFee);
      this.updateKey++;
    },
    loadClubInfo() {
      this.toggleBusy();
      this.teamExpDate = JSON.parse(localStorage.team);
      this.teamExpYear = new Date(this.teamExpDate.valid_thru).getFullYear();
      axios
          .get(this.basePath + "api/club/info", { headers: this.clubHeaders })
          .then((response) => {
            const resp = response.data.data;
            this.clubInfo = resp;
            this.loadRenewData();
            if(moment(new Date()).isAfter(moment(new Date(this.clubInfo.member.valid_thru)))) {
              this.$router.push({ path: '/club/clubmyprofile' });
            }
            this.toggleBusy();
          })
          .catch((error) => {
            console.log(error);
          });
    },
    loadRenewData(){
      if(this.clubInfo.insured_members.length > 0){
        let startDate = moment().startOf('year');
        let filteredMembers = this.clubInfo.insured_members.filter(insurance => moment(insurance.start_date) < startDate);
        let sortedMembers = filteredMembers.sort((a, b) => moment(b.start_date) - moment(a.start_date)).slice(0, 1);
        if (sortedMembers.length > 0) {
          let minDate = moment(sortedMembers[0].start_date).startOf('year');
          if (!minDate.isSame(startDate, 'day')) {
            this.clubInfo.insured_members.forEach((value, index) => {
              let insuranceDate = moment(value.start_date);
              if (insuranceDate.isSameOrAfter(minDate) && insuranceDate.isBefore(startDate)){
                this.editInsuranceRow(index, true);
                if(this.duplicateValidate()) {
                  this.clubInfo.insured_members[index].isRenew = false;
                } else if (!this.validate()) {
                    this.clubInfo.insured_members[index].isRenew = false;
                    this.clubInfo.insured_members[index].isNotValid = true;
                } else {
                  this.clubInfo.insured_members[index].isRenew = true;
                  this.clubInfo.insured_members[index].fulfilled = 0;
                  this.clubInfo.insured_members[index].fulfilled_date = null;
                  this.myInsurances.push(this.clubInfo.insured_members[index]);
                }
                this.updateKey++;
              }
            });
            this.totalQty = this.myInsurances.length;
            this.totalAmt = (this.totalQty * this.insuranceFee);
          }
        }

      }
    },
    loadOtherPrices(){
      const retry2 = setInterval(() => {
        let other_pricing = this.getSiteProps('general.other_pricing');
        if(other_pricing !== undefined){
          axios.get(this.basePath+"api/other-pricing/current-prices")
              .then(res => {
                let data = res.data.data;
                if ( Object.prototype.hasOwnProperty.call(data, "club_insurance") ) {
                  this.insuranceFee = data.club_insurance;
                }
              })
              .catch(err => {
                console.log(err);
              })
          clearInterval(retry2);
        }
      }, 200);
    }
  },
  mounted() {
    const year = new Date().getFullYear();
    this.yearArr = Array.from({length: 12}, (value, index) => year + index);
    this.loadClubInfo();
    this.loadOtherPrices();
  },
  computed: {
    insuranceNote: function(){
      return this.getSiteProps("memberRegistration.club.fields.club_insurance.add.note");
    }
  }
}
</script>

<style>
.modal-body-90 {
  width: 90% !important;
}
</style>
